.act-01 {
  height: 430px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .act-img-top {
    height: 200px !important;
  }

  .act-01 {
    height: 410px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.act-img-top {
  height: 200px !important;
}

.act-title {
  height: 35px !important;
  font-size: 14px;
  text-decoration: none !important;
}

.act-text {
  height: 35px !important;
  font-size: 14px;
}